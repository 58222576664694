import React, { Component } from 'react'
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import styles from '../components/InsightsPost/insightsPost.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import moment from "moment";
import ShareButtons from '../components/ShareButtons/ShareButtons';

export const data = graphql`
    query($slug:String){
      contentfulInsights(slug: { eq: $slug }){
          title
          description{
            description
          }
          author{
            name
          }
          publishDate
          heroImage{
            file{
              url
            }
          }
          postBody{
            json
          }
        }
    }
`

const InsightTemplate = (props) => {
    const title = props.data.contentfulInsights.title
    const url = props.location.href 
    const image= props.data.contentfulInsights.heroImage.file.url
  
    const options = {
      renderNode: {
          "embedded-asset-block": (node) => {
              const alt = node.data.target.fields.title['en-US']
              const url = node.data.target.fields.file['en-US'].url
              return <img alt={alt} src={url}  className={styles.postImg}/>
          }
      }
  }

    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.blogPostTitle}>
            <h1>{props.data.contentfulInsights.title}</h1>
          </div>
          <div className={styles.blogPostTitle}>
            <p>
              {props.data.contentfulInsights.description.description}
            </p>
          </div>
          <div className={styles.blogPostAuthor}>
             <p>{props.data.contentfulInsights.author.name} | {moment(props.data.contentfulInsights.publishDate).format("MMMM Do YYYY")}</p>
          </div>
          <div className={styles.heroImage}>
            <img src={props.data.contentfulInsights.heroImage.file.url} />
          </div>  
          <div className={styles.blogPostBody}>
             {documentToReactComponents(props.data.contentfulInsights.postBody.json, options)}
          </div>
          <div className={styles.shareButton}>
            <p>If you found this article helpful, kindly share it:</p> 
            <div className={styles.button_item}>
              <ShareButtons title={title} url={url}  image={image}/>
            </div>
          </div>
        </div>
       
      </Layout>
    )
}

export default InsightTemplate
